exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-drustvo-js": () => import("./../../../src/templates/posts-drustvo.js" /* webpackChunkName: "component---src-templates-posts-drustvo-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-posts-kultura-js": () => import("./../../../src/templates/posts-kultura.js" /* webpackChunkName: "component---src-templates-posts-kultura-js" */),
  "component---src-templates-posts-sport-js": () => import("./../../../src/templates/posts-sport.js" /* webpackChunkName: "component---src-templates-posts-sport-js" */)
}

